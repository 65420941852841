import banner from "../../assets/banner.png";
import DynamicHero from "../Hero/DynamicHero";
import useContentful from "../../Contentful";
import { useEffect, useState } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const DynamicPage = () => {
  const { fetchDynamicPages } = useContentful();
  const [pageData, setPageData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDynamicPages();
      if (data && data.length > 0) {
        setPageData(data[0].fields);
      }
    };
    fetchData();
  }, [fetchDynamicPages]);

  if (!pageData) {
    return <div className="loading">Laeb...</div>;
  }

  return (
    <div>
      <div className="hero__bg">
        <img src={banner} alt="banner" />
      </div>
      <DynamicHero
        title={pageData.pageTitle}
        description={pageData.pageDescription}
      />
      <div>
        <div className="dynamic">
          {documentToReactComponents(pageData.pageContent)}
        </div>
      </div>
    </div>
  );
};

export default DynamicPage;
