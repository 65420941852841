import React, { FC } from "react";

interface HeroProps {
  scrollToTables?: () => void;
}

const Hero: FC<HeroProps> = ({ scrollToTables }): JSX.Element => {
  return (
    <div className="hero">
      <div className="hero__wrapper">
        <div className="hero__title">
          <h1>Remondime ja teenindame kõiki sõidukimarke</h1>
          <h4>
            Kohtleme igat klienti ja tema probleemi sõidukiga individuaalselt,
            teeme koostööd oma ala professionaalidega
          </h4>
        </div>
        <button onClick={scrollToTables}>Hinnakiri</button>
      </div>
    </div>
  );
};

export default Hero;
