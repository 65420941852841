import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Hero from "./components/Hero/Hero";
import Rehvitood from "./components/Table/Rehvitood";
import Remonditoo from "./components/Table/Remonditood";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import banner from "./assets/banner.png";
import DynamicPage from "./components/pages/DynamicPage";
import useContentful from "./Contentful";

function App() {
  const tablesRef = useRef(null);
  const contactRef = useRef(null);
  const [pages, setPages] = useState<any[]>([]);
  const { fetchDynamicPages } = useContentful();

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const data = await fetchDynamicPages();
        if (data && data.length > 0) {
          setPages(
            data.map((item: any) => ({
              pageTitle: item.fields.pageTitle,
              pageRoute: item.fields.pageRoute,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching pages:", error);
      }
    };

    fetchPages();
  }, [fetchDynamicPages]);

  const scrollToContact = () => {
    const contactElement = contactRef.current as HTMLElement | null;

    if (contactElement) {
      contactElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const scrollToTables = () => {
    const tablesElement = tablesRef.current as HTMLElement | null;

    if (tablesElement) {
      tablesElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const Home = () => (
    <div className="landing">
      <div>
        <div className="hero__bg">
          <img src={banner} alt="banner" />
        </div>
        <Hero scrollToTables={scrollToTables} />
      </div>
      <div ref={tablesRef} className="tables">
        <div>
          <h1>Hinnakiri</h1>
          <div className="line-break" />
        </div>
        <Remonditoo />
        <Rehvitood />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
      <Footer />
    </div>
  );

  return (
    <Router>
      <Header
        scrollToTables={scrollToTables}
        scrollToContact={scrollToContact}
        pages={pages}
      />
      <Routes>
        <Route path="/:page" element={<DynamicPage />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
