import React, { FC } from "react";

const Footer: FC = (): JSX.Element => {
  return (
    <div className="footer">
      <div className="footer__grid">
        <div className="footer__about">
          <h6>meist</h6>
          <p>
            EDUESTA OÜ tegutseb 2002. aastast. EDUESTA OÜ alustas oma tegevust
            peaaegu 21 aastat tagasi. 2019 aastal liitus ketiga Fixus. Pakume
            oma klientidele kvaliteetseid varuosasid ning kiireid ja
            professionaalseid lahendusi sõidukite hooldusel ja remondil, tagades
            sellega autoomanikele turvalise ja ohutu sõidu.
          </p>
        </div>
        <div className="footer__partners">
          <div>
            <h6>Partnerid</h6>
            <ul>
              <li>Inter Cars Eesti OÜ</li>
              <li>Forss OÜ</li>
              <li>K.G. Knutsson AS</li>
              <li>Balti Autoosad AS</li>
              <li>Automeister AS</li>
              <li>Veho AS</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
